* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}

.main-container {
  width: 100%;
  height: 100vh;
  background:#fff;

}


/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6, p, a, button, details {
  font-family: 'Poppins', sans-serif;
}
h1 {
  color: #34495e;
  font-weight: 400;
  font-size: 1.4rem;
}

/* ITEMS */

.logo-header {
  display: grid;
  grid-template-columns: 20% auto;
  gap: 10px;
}

.logo-header img {
  width: 64px;
  height: 64px;
}
.app-container {
  width: 100vw;
  height: auto;
  display: grid;
}
.app-container-upper {
  
  width: 100vw;
  display: grid;
  place-items: center;
}
.app-container-upper-grid {
  
  display: grid;
  place-items: center;
  padding: 20px 0;

}
.app-form {
  margin:  5px 0;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;
}

.app-form-select {
  margin:  5px 0;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
}
.app-form-check {
  display: grid;
  grid-template-columns: 10% 90%;
  width: 100%;
}
.app-container-lower {
  width: 100vw;
  display: grid;
  place-items: center;
  border: 1px solid pink;
}
.app-container-lower p {
  position: relative;
}

.-check {
  display: grid;
  place-items: center;
}
h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #2AA8C3;
  font-size: 24px;
}
h1 span {
  display: block;
  color: #2c3e50;
  font-size: 48px;
  font-weight: 400;
}
h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  z-index: 1;
  
}
h2.blue {
  color: #2AA8C3;
  font-weight: 400;
  font-size: 1.2rem
}
h2.red {
  background: #1abc9c;
  display: grid;
  place-items: center;
  width: 100%;
  color: #fff;
  padding: 10px;
  font-size: 1rem;
  margin-top: 10px;
  
}
h3 {
  font-family: 'Poppins', sans-serif;
  font-size: .8rem;
  font-weight: 400;
  padding: 10px;
}
h3 span {
  font-size: .6rem;
  color: rgb(129, 127, 127);
  word-wrap: normal
}
p {
  font-size: .6rem;
  color: rgb(129, 127, 127);
  margin-left: 0px;
  margin-top: 10px;
}
input[type=number], select {
  font-size: .7rem;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  border: 1px solid #4178CB;
  background: none;
}

